import BasePlugin from '../BasePlugin'

export default class AddVerificationProgramByCapitalConstructionObject extends BasePlugin {
  async execute () {
    let me = this
    let capitalConstructionObjectId = {
      'capitalConstructionObjectId': this.context.getModel().id
    }
    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddVerificationProgramByCapitalConstructionObjectCommand`,
      capitalConstructionObjectId,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
      if (response.data.new_record) {
        this.context.$nextTick(() => {
          me.context.openRegistryCard({ 'registryId': 106, 'cardId': '47', 'cardName': '', 'recordId': null, 'initialData': { 'attr_107_': this.context.getModel().id } })
        })
      } else {
        this.context.$nextTick(() => {
          me.context.openRegistryCard({ 'registryId': 106, 'cardId': '47', 'cardName': '', 'recordId': response.data.verification_program_record_id })
        })
      }
    })
  }
}
